export default function LinkCard(props) {
    return (
    <div className="col-md-4 col-lg-3 
      col-sm-12 pb-lg-5 mb-lg-5 pb-3 mb-3">
      <div className="card h-100">
        <div className="card-body d-flex flex-column">
          <div className="h3 text-center">
            {props.title}
          </div>
          <p className="card-text text-left p-2">{props.text}</p>
          <a href={props.linkUrl} className="mt-auto">
            <button type="button" className="btn btn-warning w-100 ">
              {props.buttonTitle}
            </button>
          </a>
        </div>
      </div>
    </div>
    );
}
