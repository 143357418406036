export default function HomeBanner() {
    return (
        <div className="banner">
            <div className="h1 text-white ms-5 pt-5 colton anton-regular">COLTON PORTER</div>
            <div className="col-4 ms-5">
                <div className="h5 text-warning banner-txt">
                  DOBIE JUNIOR HIGH SCHOOL<br/>
                  CLASS OF 2029<br/>
                CIBOLO, TX</div>
            </div>
        </div>
    )
}