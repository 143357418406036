import Navbar from '../Components/Navbar';
import VidBanner from '../Components/VidBanner';
import PracticeLinks from '../Components/PracticeLinks';
import VidCard from '../Components/VidCard';
import { useEffect, useState } from 'react';
import { getVideos } from '../Services/VideoService';



export default function TravelTeam() {
    const [videos, setVideos] = useState([]);
    useEffect(() => {
        const fetchVideos = async () => {
          const apiKey = process.env.REACT_APP_YOUTUBE_API_KEY;
          const playlistId = process.env.REACT_APP_STATTEAM_PLAYLIST_ID; 
          const videos = await getVideos(playlistId, apiKey);
          setVideos(videos);
        };
      
        fetchVideos();
      }, []);

    return (
        <>
            <Navbar />
            <VidBanner title="TRAVEL TEAM" />
            <div className="container">
                {/* First few games that werent stitched together so I couldnt display them as one video */}
                <div className="row pt-5 mb-5">
                    <PracticeLinks
                        title="9/10/2023 vs. SA TUP"
                        linkUrl="/travel-team-1"
                    />
                    <PracticeLinks
                        title="9/10/2023 vs. Waco 94 Feet Elite"
                        linkUrl="/travel-team-2"
                    />
                    <PracticeLinks
                        title="9/10/2023 vs. D1 Smoke Chasers"
                        linkUrl="/travel-team-3"
                    />
                    <PracticeLinks
                        title="9/10/2023 vs. Austin THP"
                        linkUrl="/travel-team-4"
                    />
                </div>
                {/* The rest of the games that are complete games. */}
                <div className="container">
                <div className="row pt-5 mb-5 justify-content-center">
                    {videos.map((video) => (
                        <VidCard
                        key={video.id}
                        sourceUrl={`https://www.youtube.com/embed/${video.snippet.resourceId.videoId}`}
                        title={video.snippet.title}
                        text={video.snippet.description || "No description available."}
                        />
                     ))}
                </div>
                </div>
            </div>
        </>
    )
}