import Navbar from "../Components/Navbar";
import LinkCard from "../Components/LinkCard";
import HomeBanner from "../Components/HomeBanner";

export default function Home() {
    return (
        <>
            <Navbar />
            <HomeBanner />
            <div className="margins">
                <div className="container mt-5">
                    <div className="row">
                        <LinkCard
                            title = "Travel Team"
                            text = "This section features a collection of videos from different tournaments"
                            linkUrl="/travel-team"
                            buttonTitle = "View Travel Team Games"
                        /> 
                        <LinkCard
                            title = "School Games"
                            text = "Relive the most exciting moments of Colton's school game performances"
                            linkUrl="/school-team"
                            buttonTitle = "View School Games"
                        />
                        <LinkCard
                            title = "Highlight Reel"
                            text = "Step into the spotlight with Colton's personal highlight reel"
                            linkUrl="/highlights"
                            buttonTitle = "View Highlight Reels"
                        />
                        <LinkCard
                            title = "Workout Sessions"
                            text = "These videos showcase the dedication and hard work that go into perfecting each aspect of his game"
                            linkUrl="/workouts"
                            buttonTitle = "View Workout Videos"
                        />   
                    </div>
                </div>
            </div>
        </>
    );
}
