export default function Footer() {
    return (
        <div className="footer">
            <div className="h1 foot-txt pt-5 text-center">coltonporterballer.com</div>
            <div className="subtext">
                <div className="copyright-wrapper">
                    <p className="foot-copy">&copy; The Porter Group. All Rights Reserved.</p>
                </div>
            </div>
        </div>
    );
}
