import Navbar from '../Components/Navbar';
import PracticeLinks from '../Components/PracticeLinks';
import VidBanner from '../Components/VidBanner';

export default function Workouts() {
    return (
        <>
        <Navbar />
        <VidBanner title="WORKOUTS" />
        <div className="container">
        <div className="row pt-5 link-row">
                    <PracticeLinks 
                        title="Three Point Practice"
                        linkUrl="/three-point"
                    />
                    <PracticeLinks 
                        title="Buda Practice"
                        linkUrl="/buda-practice"
                    />
                    {/* <PracticeLinks 
                        title="Jumpshot Practice"
                        linkUrl="/"
                    /> */}
                </div>
            </div>
        </>
    )
}