import { NavLink } from 'react-router-dom';

export default function Navbar() {
    return (
        <nav className="navbar navbar-expand-lg navbar-dark custom-navbar">
            <div className="container-fluid d-flex justify-content-between">
                <a className="navbar-brand text-white ms-5" href="/">coltonporterballer.com</a>
                <button className="navbar-toggler text-white" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <NavLink exact to="/" className="nav-link" activeClassName="active">Home</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/school-team" className="nav-link" activeClassName="active">School Team</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/travel-team" className="nav-link" activeClassName="active">Travel Team</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/highlights" className="nav-link" activeClassName="active">Highlight Reel</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/workouts" className="nav-link" activeClassName="active">Workout Sessions</NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}
