import Navbar from '../Components/Navbar';
import VidCard from '../Components/VidCard';
import VidBanner from '../Components/VidBanner';
import { useEffect } from 'react';
import { useState } from 'react';

export default function Highlights() {
    // eslint-disable-next-line no-unused-vars
    const [videos, setVideos] = useState([{}])

    useEffect(() => {
        fetch('http://localhost:5000/videos')
          .then(response => response.json())
          .then(data => {
            console.log(data); // Log the data here
            setVideos(data);
          })
          .catch(error => {
            console.error('There was an error fetching the videos:', error);
          });
      }, []);
      
    return (
        <>
            <Navbar />
            <VidBanner 
                title = "HIGHLIGHTS"
            />
            <div className="container">
            <div className="row pt-5 mb-5 justify-content-center">
                    <VidCard 
                        sourceUrl = "https://www.youtube.com/embed/9Icu-h7n6zE?si=icMhToYCQdy8OHCg"
                        title = "Highlight Reel #1"
                        text = "Colton during the 2024 middle school season."
                    />
                    {/* <VidCard 
                        sourceUrl = "https://www.youtube.com/embed/t22flliJLTQ?si=stdYX33NqreM5mwj"
                        title = "Highlight Reel #2"
                        text = "Colton's AAU team"
                    />
                    <VidCard 
                        sourceUrl = "https://www.youtube.com/embed/NHhTMh0nURA?si=VLFLEdFIqFSgfx6Q"
                        title = "Highlight Reel #3"
                        text = "Colton's best shots"
                    /> */}
                </div>
            </div>
        </>
    )
}