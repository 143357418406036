import Navbar from '../Components/Navbar';
import VidBanner from '../Components/VidBanner';
import VidCard from '../Components/VidCard';
import { useEffect, useState } from 'react';
import { getVideos } from '../Services/VideoService';

export default function SchoolTeam() {
    const [videos, setVideos] = useState([]);
    useEffect(() => {
        const fetchVideos = async () => {
          const apiKey = process.env.REACT_APP_YOUTUBE_API_KEY;
          const playlistId = process.env.REACT_APP_SCHOOL_PLAYLIST_ID; 
          const videos = await getVideos(playlistId, apiKey);
          setVideos(videos);
        };
      
        fetchVideos();
      }, []);

    return (
        <>
            <Navbar />
            <VidBanner
                title = "SCHOOL TEAM"
            />
            <div className="container">
                <div className="row pt-5 mb-5 justify-content-center">
                    {videos.map((video) => (
                        <VidCard
                        key={video.id}
                        sourceUrl={`https://www.youtube.com/embed/${video.snippet.resourceId.videoId}`}
                        title={video.snippet.title}
                        text={video.snippet.description || "No description available."}
                        />
                     ))}
                </div>
                </div>
        </>
    )
}