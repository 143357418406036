import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './Pages/Home';
import Workouts from './Pages/Workouts';
import SchoolTeam from './Pages/SchoolTeam';
import Highlights from './Pages/Highlights';
import TravelTeam from './Pages/TravelTeam';
import ThreePoint from './Pages/ThreePoint';
import TravelGame1 from './Pages/TravelGame1';
import TravelGame2 from './Pages/TravelGame2';
import TravelGame3 from './Pages/TravelGame3';
import TravelGame4 from './Pages/TravelGame4';
import BudaPractice from './Pages/BudaPractice';
import Footer from './Components/Footer';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/workouts" element={<Workouts />} />
        <Route path='/school-team' element={<SchoolTeam />} />
        <Route path='/travel-team' element={<TravelTeam />} />
        <Route path='/highlights' element={<Highlights />} />
        <Route path='three-point' element={<ThreePoint />} />
        <Route path='travel-team-1' element={<TravelGame1 />} />
        <Route path='travel-team-2' element={<TravelGame2 />} />
        <Route path='travel-team-3' element={<TravelGame3 />} />
        <Route path='travel-team-4' element={<TravelGame4 />} />
        <Route path='/buda-practice' element={<BudaPractice />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
