// export default function VidCard(props) {
//     return (
//         <div className="col-lg-6 col-md-12">
//             <div className="card vid-card mb-4">
//                 <div className="card-body center-content">
//                 <div className="center-iframe">
//                     <iframe width="370" height="215" src={props.sourceUrl}
//                     title="YouTube video player" 
//                     frameBorder="0" 
//                     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
//                     allowFullScreen></iframe>
//                  </div>
//                 <h5 className="card-title">{props.title}</h5>
//                 <p className="card-text">{props.text}</p>
//                 </div>
//             </div>
//         </div>
//     )
// }

export default function VidCard(props) {
    return (
        <div class="card vid-card col-lg-4 col-6 col-sm-12" >
            <div className="center-iframe">
                    <iframe width="370" height="215" src={props.sourceUrl}
                    title="YouTube video player" 
                    frameBorder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowFullScreen
                    className="card-img-top mt-2"></iframe>
                 </div>
            <div class="card-body">
                <h5 class="card-title">{props.title}</h5>
                <p class="card-text">{props.text}</p>
            </div>
        </div>
    )
}


