export default function PracticeLinks(props) {
    return (
        <div className="col-lg-3 col-md-3 col-sm-6 col-12">
            <div className="card practice-card">
                <div className="card-body practice-body">
                    <div className="h3 card-title">
                        <a href={props.linkUrl} className="card-link practice-link bt-txt">
                            {props.title}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}