export async function getVideos(playlistId, apiKey) {
    const url = `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=10&playlistId=${playlistId}&key=${apiKey}`;
  
    try {
      const response = await fetch(url);
      const data = await response.json();
      return data.items || []; 
    } catch (error) {
      console.error('There was an error fetching the videos:', error);
      return []; 
    }
  }
  